import React from "react"
import { graphql, Link } from "gatsby"
import kebabCase from "lodash.kebabcase"
import Layout from "../components/Layout/Layout"
import Seo from "../components/Seo/Seo"
import Title from "../components/Headings/Title"

const CategoriesPage = ({ data }) => {
  return (
    <Layout>
      <Seo title="Categories Page" />
      <section className="category-section">
        <Title>Categories Page...</Title>

        <div className="container text-center py-5">
          {data.allMdx.group.map((category, i) => {
            return (
              <Link
                key={i}
                to={`/category/${kebabCase(category.fieldValue)}`}
                className="btn btn-primary btn shadow m-1"
              >
                <span>{category.fieldValue} </span>
                <span className="badge bg-light text-dark">
                  {category.totalCount}
                </span>
              </Link>
            )
          })}
        </div>
      </section>
    </Layout>
  )
}

export default CategoriesPage

export const query = graphql`
  query blogCategoriesList {
    allMdx(
      sort: { fields: [frontmatter___order], order: ASC }
      filter: { fileAbsolutePath: { regex: "/(blog)/.*.(mdx)/" } }
    ) {
      group(field: frontmatter___category) {
        totalCount
        fieldValue
      }
      edges {
        node {
          frontmatter {
            category
          }
        }
      }
    }
  }
`
